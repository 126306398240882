<template>
  <v-app>
    <div
      class="bg-body_ d-flex flex-column justify-center align-center"
    >
    <v-container class="mt-6">
      <div class="mx-auto mb-5 cursor-pointer topDes custom-container">
        <div >
          <img
            style="max-width: 300px;max-height: 80px"
            class="text-center"
            :src="logo.value"
            @click="index()"
          />
        </div>
        <h3 class="hidden-xs-only col-F63605">{{ $t("你身边的省钱助手") }}</h3>
      </div>
    </v-container>
    <div class="sign-up-box">
      <div class="sign-up-card-left">

      </div>
      <div class="sign-up-card">
        <div class="sign-up-card-container">
          <div class="text-left mb-2 col-F63605">
            <h3 class="mb-1">{{ $t("账号注册") }}</h3>
            <h5 class="text-sm  grey--text">
              {{ $t("请填写所有表格以继续") }}
            </h5>
          </div>
          <div class="height-80 ">
            <p class="text-14 mb-1">{{ $t("手机号码或邮箱") }}</p>
            <v-text-field
             hide-details
              placeholder="example@mail.com"
              :rules="[() => !!params.account || $t('请输入手机号或邮箱')]"
              outlined
              dense
              class=""
              v-model="params.account"
            ></v-text-field>
          </div>
          <div  class="height-80">
            <p class="text-14 mb-1">{{ $t("验证码") }}</p>
            <div class="d-flex">
              <v-text-field
                
                :rules="[() => !!params.captcha || $t('请输入验证码')]"
                outlined
                dense
                :placeholder="$t('请输入验证码')"
                v-model="params.captcha"
                class=" mr-2"

              ></v-text-field>
              <v-btn color="primary"  @click="sendcode()" v-if="!captchaOptions.status">{{
                $t("获取验证码")
              }}</v-btn>
              <v-btn v-else>{{ captchaOptions.seconds }}s</v-btn>
            </div>
          </div>
          <div  class="height-80 ">
            <p class="text-14 mb-1">{{ $t("邀请码") }}</p>
            <v-text-field
              hide-details
              :placeholder="$t('输入或粘贴邀请码')"
              outlined
              dense
              class=""
              v-model="params.inviteCode"
            ></v-text-field>
          </div>
          <div  class="height-80">
            <p class="text-14 mb-1">{{ $t("登录密码") }}</p>
            <v-text-field
              hide-details
              type="password"
              :placeholder="$t('密码只能是数字字母下划线_组合')"
              :rules="[() => !!params.password || $t('密码只能是数字字母下划线_组合')]"
              outlined
              dense
              v-model="params.password"
              class=""
            ></v-text-field>
          </div>
          <div  class="height-70 ">
            <p class="text-14 mb-1">{{ $t("确认登录密码") }}</p>
            <v-text-field
            hide-details
              type="password"
              :placeholder="$t('密码只能是数字字母下划线_组合')"
              :rules="[() => !!params.password || $t('密码只能是数字字母下划线_组合')]"
              outlined
              dense
              v-model="params.passwords"
              class=""
            ></v-text-field>
          </div>
          <div class="mb-4">
            <span>{{$t('即表示您已同意')}}</span>
           <router-link to="/forget-pwd" class="ms-2 col-F63605 text--darken-4 font-600">{{ $t("条款与注册协议") }}</router-link>
          </div>
          <v-btn
            color="primary"
            class="text-capitalize font-600 mb-2 py-5"
            block
            @click="registerfunc"
          >
            {{ $t("同意协议并注册") }}
          </v-btn>
        </div>
        <div class="py-4  lighten-2 d-flex justify-space-around">
          <div class="text-center">
            <span class="grey--text text--darken-1"
              >{{ $t("已有账号") }}？
              <router-link
                to="/sign-in"
                class="ms-2 col-F63605 text--darken-4 font-600"
                >{{ $t("去登陆") }}</router-link
              >
            </span>
          </div>
          
        </div>
      </div>
    </div>
    </div>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      checkbox: false,
      params: {
        account: "",
        password: "",
        passwords:"",
        captcha: "",
        inviteCode:'',
        
      },
      logo:{},
      captchaOptions: {
        status: false,
        seconds: 60,
        timer: null,
      },
      Rules:[(v) => !!v || "必填项"],
      caigoudialog: false, //采购商弹窗
    };
  },
  mounted() {
    let invicode=this.GetQueryString('code')
    if(invicode){
      this.params.inviteCode=invicode
    }
    this.logo=this.$store.state.config.logo[0]
  },
  methods: {
    sendcode() {
      if (this.params.account) {
        this.captchaOptions.status = true;
        this.codesub();
        this.$api.user
          .getVerifiCode({ username: this.params.account })
          .then((res) => {
            if (res.status=='error') {
              this.$Toast({ content: res.msg, type: "error" });
            }
          });
      }
    },
    codesub() {
      let seconds = 60,
        that = this;
      this.captchaOptions.timer = setInterval(() => {
        seconds--;
        that.captchaOptions.seconds = seconds;
        if (seconds < 1) {
          that.captchaOptions.status = false;
          that.captchaOptions.seconds = 60;
          clearInterval(that.captchaOptions.timer);
        }
      }, 1000);
    },
    // 注册
    registerfunc() {
      let param={
        username:this.params.account,
        password:this.params.password,
        code:this.params.captcha,
        tg_code:this.params.inviteCode
      }
      
      if(this.params.password!=this.params.passwords){
        this.$Toast({
          content: this.$t('两次密码不一致'),
          type: 'error'
        })
        return false
      }
      this.$api.signIn.register(param).then((res) => {
        if (res.status=='success') {
          this.$Toast({ content: res.msg, type: "success" });
          // this.$store.commit("setUSERINFO", res.data.user_info);
          this.$router.push('/sign-in')
        } else {
          this.$Toast({ content: res.msg, type: "error" });
        }
      });
    },
    index(){
      this.$router.push({
        path: "/",
      });
    },
    GetQueryString(name) {
     return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1]
					.replace(/\+/g, '%20')) || null
    }
  },
  destroyed() {
    clearInterval(this.captchaOptions.timer);
  },
};
</script>

<style lang="scss" scoped>
.col-F63605{
  color: #F63605 !important;
}
.height-80{
  height: 80px;
}
.bg-body_{
  width: 100%;
  background:url("../assets/images/login/loginbasebg.png");
  height:100%;			//大小设置为100%
  position:fixed;
  background-size:100% 100%;
}

.sign-up-box{
  width: 1100px;
  height: 650px;
  overflow: hidden;
  // margin: 2rem auto;
  background-color: #fff;
  background-size:100% 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background:url("../assets/images/login/loginbg.png");
  @media (max-width: 500px) {
    width: 100%;
    background-image:none
  }
}
.sign-up-card-left{
  width: 526px;
  height: 486px;
  background-size:100% 100%;
  background: url('../assets/images/login/login-left.svg');
  @media (max-width: 500px) {
    width: 0;
  }
}
.sign-up-card {
  width: 400px;
  background-color: #fff;
  // border-radius: 8px;
  // box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;
  @media (max-width: 500px) {
    width: 100%;
  }
  .sign-up-card-container {
    padding: 0rem 3.75rem 0px;
    @media (max-width: 500px) {
      padding: 3rem 1rem 0px;
    }
  }
}
</style>